import React from "react";
import Header from "../components/Header/Header";
import Pool from "../components/Pool/Pool";

function Home({connectWallet, configData, borrow, web3, signedIn, balance, stableCoinBalance, repay, debtAccrued, isConnected}) {
  return (
    <div>
      <Header connectWallet = {connectWallet}  isSignedIn = {signedIn} balance = {balance} stableCoinBalance = {stableCoinBalance} isConnected={isConnected}/>
      <Pool configData = {configData}  borrow = {borrow} web3 = {web3}  repay = {repay} debtAccrued = {debtAccrued} />
    </div>
  );
}

export default Home;
