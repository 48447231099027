import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
//import Layout from "./components/Layout";
import Home from './pages/Home'
import Web3 from 'web3/dist/web3.min.js'
import { useState, useEffect } from 'react'
import TokenContract from './abis/Token.json'
import ConfigContract from './abis/config.json'
import PoolContract from './abis/pool.json'
import convertToEther, { convertToDays } from './helpers/convertToEther'
import {formatter} from "./helpers/Formatter"

function App() {
  const [loaded, setLoaded] = useState(0)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState('')
  const [tokenContract, setTokenContract] = useState({})
  const [poolContract, setPoolContract] = useState({})
  const [configContract, setConfigContract] = useState({})
  const [balance, setBalance] = useState()
  const [stableCoinBalance, setStableCoinBalance] = useState()
  const [configData, setConfigData] = useState({})
  const [web3, setWeb3] = useState({})
  const [debtAccrued, setDebtAccrued] = useState('')
  const [isConnected, setIsConnected] = useState(false)
  console.log(loaded)
  console.log(isSignedIn)
  console.log(selectedAccount)
  console.log(tokenContract)
  console.log(poolContract)
  console.log(configContract)
  console.log(balance)
  console.log(stableCoinBalance)

  const fetchConfigData = async () => {
    try {
      const { ethereum } = window
      if (ethereum) {
        const configContract = await getConfigContract()
        const poolContract = await getPoolContract()
        const upfrontFee = convertToEther(
          getProvider(),
          await configContract.methods.getUpfrontFee().call(),
        )
        const interestFee = convertToEther(
          getProvider(),
          await configContract.methods.getInterestRate().call(),
        )
        const penaltyRate = convertToEther(
          getProvider(),
          await configContract.methods.getPenaltyRate().call(),
        )
        const grossApy = await calculateGrossApy()
        const netApy = await calculateNetApy()
        const commitmentAmount = formatter.format(convertToEther(
          getProvider(),
          await configContract.methods.getCommitmentAmountUsdValue().call(),
        ))
        const commitmentDays = convertToDays(
          await configContract.methods
            .getDurationOfCommitmentAgreementInDays()
            .call(),
        )
        const poolConfig = await poolContract.methods
          .poolConfigs(contractAddresses.tokenContract)
          .call()
        const tvl = formatter.format(convertToEther(getProvider(), poolConfig.totalDeposit))
        const borrowed = formatter.format(convertToEther(getProvider(), poolConfig.totalBorrows))
        const config = {
          upfrontFee,
          interestFee,
          penaltyRate,
          grossApy,
          netApy,
          commitmentAmount,
          durationRemaining: commitmentDays,
          borrowed,
          tvl,
        }
        await setConfigData(config)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    isMetaMaskConnected()
    fetchConfigData()
    getDebtAccrued()
    getNativeTokenBalance()
    geTokenBalance()
  }, [])

  const contractAddresses = {
    tokenContract: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    poolContract: process.env.REACT_APP_POOL_CONTRACT_ADDRESS,
    configContract: process.env.REACT_APP_CONFIG_CONTRACT_ADDRESS,
  }

  const calculateGrossApy = async () => {
    const poolContract = await getPoolContract()
    const poolConfig = await poolContract.methods
      .poolConfigs(contractAddresses.tokenContract)
      .call()
    const totalInterestPaid = convertToEther(
      getProvider(),
      poolConfig.totalInterestPaid,
    )
    let rateWeekly = (+totalInterestPaid / 7) / +convertToEther(getProvider(), poolConfig.totalDeposit);
   let apyWeekly = (((1 + rateWeekly) ** (365 / 7)) - 1) * 100;
    // const total =
    //   +interestPaid /
    //   +convertToEther(getProvider(), poolConfig.totalDeposit) /
    //   (31 / 365)
    if (isNaN(apyWeekly)) {
      return 0.000000
    }
    
    return +apyWeekly.toFixed(6)
  }

    const calculateNetApy = async () => {
      const configContract = await getConfigContract()
      const grossApy = await calculateGrossApy()
      const result = (
        grossApy *
        convertToEther(
          getProvider(),
          await configContract.methods.getProtocolFee().call(),
        )
      )

      if (isNaN(result)) {
        return 0.000000
      }
    
      return  +result.toFixed(6)
    }
  

  const connectWallet = async () => {
    let provider = window.ethereum
    if (!provider) {
      console.log('Meta mask not detected')
    }
    if (typeof provider !== 'undefined') {
      const web3 = new Web3(provider)
      setWeb3(web3)
      console.log('provider', provider)
      provider
        .request({ method: 'eth_requestAccounts' })
        .then(async (accounts) => {
          console.log('signedIn', 'here')
          setSelectedAccount(accounts[0])
          setIsSignedIn(true)
          setLoaded(2)

          localStorage.setItem('activeAccount', accounts[0])
          await setInitialConfigurations(web3, accounts[0])
          window.location.reload(false)
        })
        .catch((err) => {
          console.log(err)
          return
        })

      window.ethereum.on('accountsChanged', async function (accounts) {
        setLoaded(1)
        setSelectedAccount(accounts[0])
        console.log(`Selected account changed to ${accounts[0]}`)
        localStorage.setItem('activeAccount', accounts[0])
        setTimeout(() => setLoaded(2), 1000)
        await setInitialConfigurations(web3, accounts[0])
      })
    }
  }

  const geTokenBalance = async () => {
    try {
      const web3 = getProvider()
      const tokenContract = await new web3.eth.Contract(
        TokenContract.abi,
        contractAddresses.tokenContract,
      )
      const address = localStorage.getItem('activeAccount')
      const balanceInWei = await tokenContract.methods.balanceOf(address).call()
      const balanceInEther = convertToEther(web3, balanceInWei)
      setStableCoinBalance(balanceInEther)
    } catch (err) {
      console.log('err', err)
    }
  }

  const getNativeTokenBalance = async () => {
    const web3 = getProvider()
    const address = localStorage.getItem('activeAccount')
    const balanceInWei = await web3.eth.getBalance(address)
    const balanceInEther = convertToEther(web3, balanceInWei)
    console.log('token balance', balanceInEther)
    setBalance(balanceInEther)
  }

  const getProvider = () => {
    let provider = window.ethereum
    if (provider) {
      return new Web3(provider)
    }
    console.log('ethereum not present')
  }
  const getTokenContract = async () => {
    const web3 = getProvider()
    return await new web3.eth.Contract(
      TokenContract.abi,
      contractAddresses.tokenContract,
    )
  }

  const getConfigContract = async () => {
    const web3 = getProvider()
    return await new web3.eth.Contract(
      ConfigContract.abi,
      contractAddresses.configContract,
    )
  }

  const getPoolContract = async () => {
    const web3 = getProvider()
    return await new web3.eth.Contract(
      PoolContract.abi,
      contractAddresses.poolContract,
    )
  }

  const borrow = async (amount) => {
    const poolContract = await getPoolContract()
    const acct = localStorage.getItem('activeAccount')
    await poolContract.methods
      .borrow(contractAddresses.tokenContract, amount)
      .send({ from: acct })
  }

  const repay = async (amount) => {
    const poolContract = await getPoolContract()
    const tokenContract = await getTokenContract()
    const acct = localStorage.getItem('activeAccount')
    await tokenContract.methods
      .approve(contractAddresses.poolContract, amount)
      .send({ from: acct })
    console.log('acct', acct)
    await poolContract.methods
      .repay(contractAddresses.tokenContract, amount)
      .send({ from: acct })
  }

  const getDebtAccrued = async () => {
    const poolContract = await getPoolContract()
    const acct = localStorage.getItem('activeAccount')
    const debt = await poolContract.methods
      .getUserDebtAccrued(contractAddresses.tokenContract, acct)
      .call()
    console.log('accrued', debt)
    setDebtAccrued(formatter.format(convertToEther(getProvider(), debt)))
  }

  const isMetaMaskConnected = async () => {
    const { ethereum } = window
    const accounts = await ethereum.request({ method: 'eth_accounts' })
    if (accounts && accounts.length > 0) {
      await setIsConnected(true)
    }

    console.log('xxx', accounts)
     //setIsConnected(false)
  }

  const setInitialConfigurations = async (web3, address) => {
    const tokenContract = await new web3.eth.Contract(
      TokenContract.abi,
      contractAddresses.tokenContract,
    )

    const configContract = await new web3.eth.Contract(
      ConfigContract.abi,
      contractAddresses.configContract,
    )

    const poolContract = await new web3.eth.Contract(
      PoolContract.abi,
      contractAddresses.poolContract,
    )

    setTokenContract(tokenContract)

    setConfigContract(configContract)

    setPoolContract(poolContract)

    // console.log("tokenContract", tokenContract)
    //const mint = await tokenContract.methods.mint(address, "100000000000000000000000").send({ from: address })
    //  console.log("mint", mint)
    //   console.log("mint", stableCoinBalance)
  
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              connectWallet={() => connectWallet()}
              configData={configData}
              borrow={borrow}
              web3={getProvider}
              signedIn={isSignedIn}
              balance={balance}
              stableCoinBalance={stableCoinBalance}
              repay={repay}
              debtAccrued={debtAccrued}
              isConnected={isConnected}
            />
          }
        />
        {/* //<Route path="/" element={ <Layout children = {Home}  /> } /> */}
      </Routes>
    </Router>
  )
}

export default App
