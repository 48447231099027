import React, { useState } from 'react'
import './Pool.css'
import convertToWei from '../../helpers/convertToWei'
function Pool({ configData, borrow, web3, repay, debtAccrued }) {
  const [amount, setAmount] = useState('')
  const [repayAmount, setRepayAmount] = useState('')
  const [loading, setLoading] = useState(false)
  const [repayLoading, setRepayLoading] = useState(false)

  const inputHandler = (e) => {
    e.preventDefault()
    setAmount(convertToWei(web3(), e.target.value))
  }
  const onSubmitHandler = async (e) => {
    setLoading(true)
    e.preventDefault()

    borrow(amount).then((d) => {
      closeModal()
      setLoading(false)
      window.location.reload(false)
    })
  }

  const repayInputHandler = (e) => {
    e.preventDefault()
    setRepayAmount(convertToWei(web3(), e.target.value))
  }

  const repayDebt = async (e) => {
    e.preventDefault()
    setRepayLoading(true);
    repay(repayAmount).then(d => {
      setRepayLoading(false);
      window.location.reload(false)
    })
  }

  const closeModal = (e) => {
    document.getElementById('closeButton').click()
  }

  const getCloseButton = () => {
    if (loading) return <p></p>
    return (
      <button
        id="closeButton"
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Close
      </button>
    )
  }

  return (
    <div className="pool">
      <div className="pool-container">
        <h1>Pool</h1>
        <div className="body">
          <div className="pool-header">
            <div className="left-header">
              <div className="avatar"></div>
              <div>
                <h3>HD Resources RCF</h3>
                <p>Ugandan Financial Services Loans</p>
              </div>
            </div>
            <div>
              <p className="rate">7.50% USDC</p>
            </div>
          </div>
          <div className="pool-main">
            <div className="box">
              <div className="detail">
                <div>TVL</div>
                <div>{configData.tvl}</div>
              </div>
              <div className="detail">
                <div>Borrowed</div>
                <div>{configData.borrowed}</div>
              </div>
              <div className="detail">
                <div>Commitment Amount</div>
                <div>{configData.commitmentAmount}</div>
              </div>
              <div className="detail">
                <div>Duration Remaining</div>
                <div>{configData.durationRemaining} days</div>
              </div>
            </div>
            <div className="box">
              <div className="detail">
                <div>Upfront Fee</div>
                <div>{configData.upfrontFee * 100}%</div>
              </div>
              <div className="detail">
                <div>Interest Fee</div>
                <div>{configData.interestFee * 100}%</div>
              </div>
              <div className="detail">
                <div>Penalty Rate</div>
                <div>{configData.penaltyRate * 100}%</div>
              </div>
              <div className="detail">
                <div>Gross APY</div>
                <div>{configData.grossApy}%</div>
              </div>
              <div className="detail">
                <div>Net APY</div>
                <div>{configData.netApy}%</div>
              </div>
              <div className="btns">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="deposit "
                >
                  Borrow
                </button>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  className="withdraw"
                >
                  Repay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={onSubmitHandler}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Borrow
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <input
                  placeholder="Amount"
                  type="text"
                  className="form-control"
                  onChange={inputHandler}
                />
                <p className="fee">Estimated Fee: 0.3%</p>
              </div>
              <div className="modal-footer">
                <button
                  id="closeButton"
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <button
                    type="button"
                    className="confirm"
                    onClick={onSubmitHandler}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

     {/* Repay modal */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form >
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Repay
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <input
                  placeholder="Amount"
                  type="text"
                  className="form-control"
                  onChange={repayInputHandler}
                />
                <p className="fee">Estimated Fee: 0.3%</p>
                <p className="fee">Debt: {debtAccrued}</p>
              </div>
              <div className="modal-footer">
                <button
                  id="closeButton"
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {repayLoading ? (
                  <p>Loading...</p>
                ) : (
                  <button
                    type="button"
                    className="confirm"
                    onClick={repayDebt}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pool
